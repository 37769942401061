export const commonsStyles = {
  media: {
    desktop: '@media(min-width: 762px)',
    mobile: '@media(max-width: 761px)',
    smallMobile: '@media(max-width: 580px)',
    tinyMobile: '@media(max-width: 460px)',
  },
  containerSize: '700px',
  contentSize: '700px',
  containerSpace: 'var(--container-space)',
};

const theme = {
  light: {
    isDark: false,
    ...commonsStyles,
    backgroundColor: '#fafafa',
    highContrastBg: '#FFF',
    lightBackground: '#e5e5e5',
    textColor: 'hsla(0,0%,0%,0.8)',
    softTextColor: '#6c7693',
    colorNegative: '#f1f1f1',
    navigationBg: '#fff',
    softHighlight: '#fafafa',
    highlightColor: '#1071dc',
    strongHighlightColor: '#1071dc',
    articleHeadingColor: 'hsl(225deg, 25%, 20%)',
  },
  dark: {
    isDark: true,
    ...commonsStyles,
    backgroundColor: '#0e131b',
    highContrastBg: '#000',
    lightBackground: '#000',
    textColor: '#f1f1f1',
    softTextColor: '#cacacb',
    colorNegative: '#f1f1f1',
    navigationBg: '#182635',
    softHighlight: 'rgba(14, 14, 14, 0.63)',
    highlightColor: '#6ab0f3',
    strongHighlightColor: '#8c6af3',
    articleHeadingColor: '#f3be6a',
  },
};

export default theme;

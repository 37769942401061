// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-newsletter-jsx": () => import("./../src/pages/newsletter.jsx" /* webpackChunkName: "component---src-pages-newsletter-jsx" */),
  "component---src-pages-notes-jsx": () => import("./../src/pages/notes.jsx" /* webpackChunkName: "component---src-pages-notes-jsx" */),
  "component---src-pages-productive-time-calculator-jsx": () => import("./../src/pages/productive-time-calculator.jsx" /* webpackChunkName: "component---src-pages-productive-time-calculator-jsx" */),
  "component---src-templates-category-jsx": () => import("./../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-post-jsx": () => import("./../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}


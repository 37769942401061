import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'emotion-theming';

import theme from './theme';

export const ThemeContext = React.createContext({});
export const ThemeContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  const hasStorage = (function() {
    try {
      localStorage.setItem('t', 't');
      localStorage.removeItem('t');
      return true;
    } catch (exception) {
      return false;
    }
  })();

  const contextValue = {
    theme: darkMode ? theme.dark : theme.light,
    darkMode,
    toggleDarkMode() {
      if (hasStorage) {
        localStorage.setItem('oDarkMode', `${!darkMode}`);
      }

      setDarkMode(!darkMode);
    },
  };

  useEffect(() => {
    const colorSchemePreference =
      typeof window !== 'undefined'
        ? window.matchMedia('(prefers-color-scheme: dark)').matches
        : false;

    if (hasStorage) {
      const darkModePreference = window?.localStorage.getItem('oDarkMode');

      // If the user has a preference, use it, otherwise use the color scheme
      setDarkMode(
        darkModePreference !== null
          ? darkModePreference === 'true'
          : colorSchemePreference,
      );
    }
  }, []);

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={contextValue.theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
